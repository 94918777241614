
export const configs_gelabs_annoBase = {
    contentTypes: ["immersion1AFGDNigeria", "immersion1ATemplates"],
    filterConfigs: {
        docTags: {
            whitelist: []
        },
        
        
    }
}