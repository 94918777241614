export const prepareData = ({ data, activeFilters }) => {
  // return data
  const repeaterInstances = [];
  const imageInstances = [];
  const imageGalleryInstances = []
  //this is just in case we shift to lazyload tomorrow
  const flattened = data.pages
    ? data.pages.reduce((a, b) => [...a, ...b.data], [])
    : data.data;

  flattened.forEach((d) => {
    if(d.main?.uploadImagesRelatedToTheActivity || d.main?.uploadImagesRelatedToThisActivity || d.main?.uploadImagesRelatedToThisVisit || d.main?.uploadImages){
      imageGalleryInstances.push(d)
    }
    if (d.main?.images) {
      imageInstances.push(d);
    } else if (d.main?.imagesRepeater) {
      repeaterInstances.push(d);
    }
  });


  const flattendGalleryImages = imageGalleryInstances.reduce((acc, item) => {
    const images = item.main?.uploadImagesRelatedToTheActivity || 
                   item.main?.uploadImagesRelatedToThisActivity || 
                   item.main?.uploadImagesRelatedToThisVisit || 
                   item.main?.uploadImages || [];
  
    const imageGalleryData = images.map(image => ({
      ...image,
      metaData: {
        stakeholder: item.tags?.stakeholder,
        subject: item.tags?.subject,
        facilityType: item.facilityType,
        contentType: item.meta.kp_content_type,
        country: item.tags?.country,
        states: item.tags?.states,
        districts : item.tags?.districts
      }
    }));
  
    return [...acc, ...imageGalleryData];
  }, []);


  const flattenedRepeater = repeaterInstances
    .reduce(
      (a, b) => [
        ...a,
        ...b.main.imagesRepeater.map((d) => ({
          ...d,
          contentType: b.meta.kp_content_type,
        })),
      ],
      []
    )
    .reduce((a, b) => {
      console.log({ fajhfaf: b });
      const metaData = {
        stakeholder: b.stakeholder,
        facilityType: b.facilityType,
        subject: b.subject,
        contentType: b.contentType,
        country: b.country,
        states: b.states,
        districts : b.districts

      };
      const imagesAry = b.image?.map((i) => ({
        ...i,
        metaData,
      }));

      return [...a, ...(imagesAry || [])];
    }, []);

  const flattenedImages = imageInstances.reduce((a, b) => {
    const metaData = {
      stakeholder: b.tags.stakeholder,
      facilityType: b.main.facilityType,
      subject: b.tags.subject,
      contentType: b.meta.kp_content_type,
    };
    const imagesAry = b.main.images.map((i) => ({
      ...i,
      metaData,
    }));

    return [...a, ...imagesAry];
  }, []);

  const toReturn = [...flattenedRepeater, ...flattenedImages, ...flattendGalleryImages];

  //now run the filters.

  if (activeFilters.length === 0) {
    return toReturn;
  } else {
    // return toReturn;
    let filteredData = [...toReturn];
    const contentTypeFilter = activeFilters.find(
      (d) => d.filterId === "contentType" && d.values?.length > 0
    );
    if (contentTypeFilter) {
      filteredData = filteredData.filter((d) =>
        contentTypeFilter.values.some((v) => v.value === d.metaData.contentType)
      );
    }

    const stakeholderFilter = activeFilters.find(
      (d) => d.filterId === "stakeholder" && d.values?.length > 0
    );
    if (stakeholderFilter) {
      filteredData = filteredData.filter(
        (d) =>
          d.metaData.stakeholder?.data?.length > 0 &&
          stakeholderFilter.values.some((v) =>
            d.metaData.stakeholder.data.some((dd) => dd._id === v.value)
          )
      );
    }

    const conditionAreasFilter = activeFilters.find(
      (d) => d.filterId === "conditionAreas" && d.values?.length > 0
    );
    if (conditionAreasFilter) {
      filteredData = filteredData.filter(
        (d) =>
          d.metaData.conditionAreas?.data?.length > 0 &&
        conditionAreasFilter.values.some((v) =>
            d.metaData.conditionAreas.data.some((dd) => dd._id === v.value)
          )
      );
    }

    const teamsFilter = activeFilters.find(
      (d) => d.filterId === "teams" && d.values?.length > 0
    );
    if (teamsFilter) {
      filteredData = filteredData.filter(
        (d) =>
          d.metaData.teams?.data?.length > 0 &&
        teamsFilter.values.some((v) =>
            d.metaData.teams.data.some((dd) => dd._id === v.value)
          )
      );
    }

    const subjectFilter = activeFilters.find(
      (d) => d.filterId === "subject" && d.values?.length > 0
    );
    if (subjectFilter) {
      filteredData = filteredData.filter(
        (d) =>
          d.metaData.subject?.data?.length > 0 &&
          subjectFilter.values.some((v) =>
            d.metaData.subject.data.some((dd) => dd._id === v.value)
          )
      );
    }

    const countryFilter = activeFilters.find(
      (d) => d.filterId === "country" && d.values?.length > 0
    );
    
    if (countryFilter) {
      filteredData = filteredData.filter(
        (d) =>
          d.metaData.country?.data?.length > 0 &&
          countryFilter.values.some((v) =>
            d.metaData.country.data.some((dd) => dd._id === v.value)
          )
      );
    }
    const statesFilter = activeFilters.find(
      (d) => d.filterId === "states" && d.values?.length > 0
    );

    if (statesFilter) {
      filteredData = filteredData.filter(
        (d) =>
          d.metaData.states?.data?.length > 0 &&
        statesFilter.values.some((v) =>
            d.metaData.states.data.some((dd) => dd._id === v.value)
          )
      );
    }
    const districtsFilter = activeFilters.find(
      (d) => d.filterId === "districts" && d.values?.length > 0
    );
    if (districtsFilter) {
      filteredData = filteredData.filter(
        (d) =>
          d.metaData.districts?.data?.length > 0 &&
              districtsFilter.values.some((v) =>
            d.metaData.districts.data.some((dd) => dd._id === v.value)
          )
      );
    }

    const facilityTypeFilter = activeFilters.find(
      (d) => d.filterId === "facilityType" && d.values?.length > 0
    );
    if (facilityTypeFilter) {
      filteredData = filteredData.filter(
        (d) =>
          !!d.metaData.facilityType === true &&
          facilityTypeFilter.values.some(
            (v) => v.value === d.metaData.facilityType.value
          )
      );
    }

    return filteredData;
  }
};
