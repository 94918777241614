import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import {
  Container,
  makeArrayFromLength,
  SANS_4,
  SkeletonLoader,
  TagDisplay,
  WrapperCardGrid,
  OKELink,
  colors,
  SANS_4_5,
} from "oolib";
import { CardBuilder, CardExpandComps } from "../Synthesis/comps";
import { __GetContentTypeConfigNew } from "../../utils/getters/gettersV2";
import {
  stockResearchNotesCardConfig,
  stockResearchNotesExpandCompConfig,
} from "./config";
const CardContainer = ({
  handleCardClick,
  expandCard,
  // tagCategory,
  // tag,
  data,
}) => {
  const tagCategory = {
    display: __GetContentTypeConfigNew(data.meta.kp_content_type).general
      .content.title,
    value: data.meta.kp_content_type,
  };
  const tag = { display: data.main.title, value: data.tagId };

  const linkTo = `/published-page/${tagCategory.value}?id=${data._id}&activeTab=annotations`;

  // hardcoded for now

  const immersion1ATemplatesDocs = data.immersion1ATemplatesDocs || [];
  const immersion1AFGDNigeriaDocs = data.immersion1AFGDNigeriaDocs || [];

  const annotations = immersion1ATemplatesDocs
    .concat(immersion1AFGDNigeriaDocs)
    .slice(0, 4);

  return (
    <div
      style={{
        border: "1px solid #e5e5e5",
        borderRadius: "4px",
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        background: colors.greyColor10
      }}
    >
      <div style={{ display: "flex", gap: "1rem" }}>
        <TagDisplay
          style={{ textTransform: "capitalize" }}
          tagColor={colors.blue}
          textColor="white"
          display={tagCategory.display}
          S
        />
        <SANS_4_5 capitalize semibold>
          <OKELink
            iconAfter="ArrowLineUpRight"
            // linkType="action"
            to={linkTo}
            iconSize={24}
            color={"black"}
          >
            {tag.display}
          </OKELink>
        </SANS_4_5>
      </div>

      {data.main.desc ? <SANS_4>{data.main.desc.allText}</SANS_4> : null}

      <WrapperCardGrid style={{ padding: "1rem 0" }}>
        {annotations.length ? (
          <CardBuilder
            onCardClick={(d) => handleCardClick({ ...d, tagCategory, tag })}
            data={annotations}
            annoTagType={tagCategory.value}
            listingCardConfig={stockResearchNotesCardConfig}
            activeCardId={
              expandCard && expandCard.tag.value === tag.value
                ? expandCard._id
                : ""
            }
            tagId={tag}
          />
        ) : (
          <SANS_4>No Annotations</SANS_4>
        )}
      </WrapperCardGrid>
    </div>
  );
};
const genSkeletonCards = (int) =>
  makeArrayFromLength(int).map(() => (
    <SkeletonLoader
      style={{
        height: "20rem",
      }}
    />
  ));
export const TagWithAnnoationsStyle = ({
  data,
  tagCategory,
  tag,
  searchSortBarRef,
  status,
  isFetchingNextPage,
  observerRef,
}) => {
  const [expandCard, setExpandCard] = useState(false);

  const handleCardClick = (d) => {
    // clicked on the same card again then close the card
    if (
      expandCard &&
      d.tag.value === expandCard.tag.value &&
      d._id === expandCard._id
    ) {
      setExpandCard(false);
    } else {
      setExpandCard(d);
    }
  };

  // return null

  if (status === "error") {
    return (
      <Container>
        <SANS_4>Something went wrong!</SANS_4>
      </Container>
    );
  }
  return (
    <div style={{ flex: 1, display: "flex" }}>
      <Container style={{ width: "100%" }}>
        {status === "loading"
          ? genSkeletonCards(2)
          : data.map((d, i) => (
              <div
                style={{ marginBottom: "2rem" }}
                ref={data.length === i + 1 ? observerRef : null}
              >
                <CardContainer
                  data={d || {}}
                  handleCardClick={handleCardClick}
                  tagCategory={tagCategory}
                  tag={tag}
                  expandCard={expandCard}
                />
              </div>
            ))}
        {isFetchingNextPage ? genSkeletonCards(2) : null}
      </Container>
      <AnimatePresence>
        {expandCard && (
          <CardExpandComps
            expandCard={expandCard}
            handleClose={() => setExpandCard(false)}
            annoTagType={expandCard.tagCategory}
            tagId={expandCard.tag}
            stickBelowElementRef={searchSortBarRef}
            listingExpandCompConfig={stockResearchNotesExpandCompConfig}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
