import {
  boxShadow1,
  colors,
  Container,
  Divider,
  DropdownMulti,
  getBreakPoint,
  getVal,
  LABEL,
  makeArrayFromLength,
  PaddingTopBottom20,
  SANS_2,
  SANS_4,
  SkeletonLoader,
  TagLink,
  transition,
  useScreenWidth,
} from "oolib";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { getTenant } from "../../../TENANT_CONFIGS";
import { useElemInView } from "../../../utils/customHooks/useElemInView";
import { useGetQueryData } from "../../../utils/react-query-hooks/general";
import { useGetDataQuery } from "../../../utils/react-query-hooks/getData";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";
import FilterModule from "../../discovery/FilterModule";
import ErrorStates from "../../ErrorStates";
import { MetaPrimary } from "../../generalUI/V2_MetaComps/MetaPrimary";
import LexicalTextEditor from "../../inputs/TextEditor/LexicalTextEditor";
import { SidebarLayoutV2 } from "../../layout/SidebarLayoutV2";
import { annotationExplorerConfigs } from "./configs/_EXPORTS";
import TagsInputMulti from "../../inputs/DynamicTagsInputs/TagsInputMulti";

const getTplsWithAnnosEnabled = ({ allTpls }) => {
  return allTpls.filter((tpl) => {
    const allBlocks = extractAllBlocksFromTpl({ tpl });
    return (
      allBlocks.filter((d) => d.props?.annotation?.enable === true)?.length > 0
    );
  });
};

const content = {
  annoTagsTitle: "Annotation Tags",
  docTagsTItle: "Document Tags",
};

const separateEachAnnoIntoIndependentDoc = ({ data, selectedTpls }) => {
  const annoFields = selectedTpls
    .map((tpl) => {
      const allBlocks = extractAllBlocksFromTpl({ tpl });
      return {
        contentType: tpl.kp_content_type,
        annoFields: allBlocks
          .filter((d) => d.props.annotation?.enable === true)
          .map((d) => d.valuePath),
      };
    })
    .reduce((a, b) => ({ ...a, [b.contentType]: b.annoFields }), {});
console.log({annoFields, selectedTpls})
  return data.data
    .filter(doc => selectedTpls.some(tpl => tpl.kp_content_type === doc.meta.kp_content_type))
    .map((doc) => {
      console.log({doc})
      const allAnnosInThisDoc = annoFields[doc.meta.kp_content_type]
        .map((valuePath) => {
          const annoDataForThisField = getVal(doc, valuePath)?.annoData;
          return (
            annoDataForThisField &&
            Object.keys(annoDataForThisField).map((d, i) => ({
              ...annoDataForThisField[d],
              annoKey: d,
            }))
          );
        })
        .filter(Boolean)
        .reduce((a, b) => [...a, ...b], []);
      console.log({ allAnnosInThisDoc });
      return allAnnosInThisDoc.map((d) => {
        const toReturn = {
          ...d,
          annoTags: d.tags,
          docTags: doc.tags,
          _id: doc._id,
          meta: {
            kp_content_type: doc.meta.kp_content_type,
            contentTypeTitle: selectedTpls.find(
              (d) => d.kp_content_type === doc.meta.kp_content_type
            )?.general?.content?.title,
          },
        };
        delete toReturn.tags;
        return toReturn;
      });
    })
    .reduce((a, b) => [...a, ...b], []);
};

const autoGenFilterConfigsFromTpl = ({ selectedTpls, _ProfileTypes }) => {
  const combinedBlocks = selectedTpls
    .map((tpl) => {
      const allBlocks = extractAllBlocksFromTpl({ tpl });
      return allBlocks;
    })
    .reduce((a, b) => [...a, ...b], [])
    .filter((bl) => {
      return bl.valuePath.startsWith("tags.")
        ? true
        : bl.props?.annotation?.enable
        ? true
        : false;
    });

    const blocksPerTpl = selectedTpls.map(tpl => ({
      contentType: tpl.kp_content_type,
      blocks: extractAllBlocksFromTpl({tpl}).reduce((a,b) => {
        if(b.valuePath.startsWith('tags.')){
          return ({
            ...a,
            docTags: [
              ...(a.docTags || []),
              b.props.tagType
            ]
          })
        }else if(b.props?.annotation?.enable){
          return ({
            ...a,
            annoTags: Array.from(new Set([
              ...(a.annoTags || []),
              ...(b.props.annotation.tagTypesConfig?.map((d) => d.tagType) || [])
            ]))
          })
        }else{
          return a
        }
      },{})
    }))

    function intersection(...arrays) {
      if (arrays.length === 0) return [];
      
      return arrays.reduce((acc, array) =>
        acc.filter(item => array.includes(item))
      );
    }
    
    function uniqueElements(arrays) {
      const inter = intersection(...arrays);  // Get the intersection first
    
      return arrays.map(array => 
        array.filter(item => !inter.includes(item))  // Remove intersection items from each array
      );
    }

    console.log({
      blocksPerTpl,
      common: {
        annoTags: intersection(...blocksPerTpl.map(d => d.blocks.annoTags)),
        docTags: intersection(...blocksPerTpl.map(d => d.blocks.docTags)),
      },
      uniqueElements: {
        annoTags: uniqueElements(blocksPerTpl.map(d => d.blocks.annoTags)).map((res,i) => ({contentType: blocksPerTpl[i].contentType, uniqueElements: res})),
        docTags: uniqueElements(blocksPerTpl.map(d => d.blocks.docTags)).map((res,i) => ({contentType: blocksPerTpl[i].contentType, uniqueElements: res})),
      }
    })

  const extractedDocTagTypes = combinedBlocks
    .map((d) => {
      if (d.valuePath.startsWith("tags.")) {
        return d.props.tagType;
      } else {
        return undefined;
      }
    })
    .filter(Boolean);

  const extractedAnnoTagTypes = combinedBlocks.reduce((a, b) => {
    console.log({ a, b });
    if (b.props.annotation?.enable) {
      return [
        ...a,
        ...(b.props.annotation.tagTypesConfig?.map((d) => d.tagType) || []),
      ];
    } else {
      return a;
    }
  }, []);

  const totalTagsList = [
    {
      filterScope: "annoTags",
      title: content.annoTagsTitle,
      annoTags: Array.from(new Set(extractedAnnoTagTypes)),
    },
    {
      filterScope: "docTags",
      title: content.docTagsTItle,
      docTags: Array.from(new Set(extractedDocTagTypes)).filter((d) => {
        const tenant = getTenant();
        const whitelistArray =
          annotationExplorerConfigs[tenant]?.filterConfigs?.docTags?.whitelist;
        if (!whitelistArray || whitelistArray.length === 0) {
          return true;
        } else {
          return whitelistArray.includes(d);
        }
      }),
    },
  ];

  const totalTagBasedFilterConfigs = totalTagsList.map((d) => ({
    sectionId: d.filterScope,
    sectionTitle: d.title,
    configs: d[d.filterScope].map((dd) => ({
      filterId: `${d.filterScope}_${dd}`,
      source: _ProfileTypes.some((p) => p.id === dd)
        ? {
            filterType: "profileTagType",
            profileTypes: [dd],
            scope: d.filterScope,
          }
        : {
            filterType: "tagType",
            tagType: dd,
            scope: d.filterScope,
          },
      target: {},
    })),
  }));

  return totalTagBasedFilterConfigs;
};

const filterAsPerActiveFilters = ({ separatedData, activeFilters, selectedTpls }) => {
  //first filter down to only the selected tpls 
  return separatedData.filter((d) => {
    if (
      activeFilters.every((a) =>
        a.values.some((v) => {
          if (a.filterId === "contentTypeFilter") {
            return d.meta.kp_content_type === v.value;
          } else {
            return d[a.source.scope]?.[
              a.source.tagType || a.source.profileTypes?.[0]
            ]?.data.some((dd) => dd._id === v.value);
          }
        })
      )
    ) {
      return true;
    } else {
      return false;
    }
  });
};

const getProperTitleFromCollectionId = ({ collectionId, allTpls }) => {
  const thisTpl = allTpls.find((d) => d.kp_content_type === collectionId);
  if (!thisTpl || !thisTpl.general?.content?.title) return collectionId;
  //else
  return thisTpl.general.content.title;
};

const genTagSectionOnCard = ({ tagsData, allTpls, title }) => (
  <div>
    <div style={{ marginBottom: "-1rem", display: "flex" }}>
      <SANS_2
        style={{
          color: colors.greyColor50,
          background: colors.white,
          paddingRight: "0.5rem",
          paddingLeft: "0.5rem",
          marginLeft: "0.5rem",
        }}
      >
        {title}
      </SANS_2>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: `1px solid ${colors.greyColor10}`,
        borderRadius: "0.5rem",
        padding: "1rem",
        paddingTop: "2rem",
      }}
    >
      {Object.values(tagsData).map((d) => {
        if (!d || !d?.data || d.data?.length === 0) return null;
        return (
          <div>
            <LABEL style={{ paddingBottom: "0.2rem" }}>
              {getProperTitleFromCollectionId({
                collectionId: d.collectionId,
                allTpls,
              })}
            </LABEL>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5rem",
              }}
            >
              {d.data?.map((dd) => (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <TagLink
                    target={"_blank"}
                    XS
                    display={dd.display}
                    to={`/published-page/${d.collectionId}?id=${dd._id}`}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export const AnnoBase = () => {
  const allTpls = useGetQueryData("allTpls");
  const { _ProfileTypes } = useGetQueryData("platformConfigs");
  const tplOptions = useRef(getTplsWithAnnosEnabled({ allTpls: annotationExplorerConfigs[getTenant()]?.contentTypes ? allTpls.filter(d => annotationExplorerConfigs[getTenant()]?.contentTypes.includes(d.kp_content_type)) : allTpls }));
  const [selectedTpls, setSelectedTpls] = useState(tplOptions.current);

  const [activeFilters, setActiveFilters] = useState([]);

  //performance optimization
  const initialLimit = 20;
  const [cardsLimit, setCardsLimit] = useState(initialLimit);
  const { observerRef } = useElemInView({
    callback: () =>
      setCardsLimit((prev) => {
        return prev + initialLimit;
      }),
  });

  // console.log({activeFilters})

  const fieldsToProject = tplOptions.current
    .map((tpl) => {
      const allBlocks = extractAllBlocksFromTpl({ tpl });

      return allBlocks.filter((bl) => bl.props.annotation?.enable === true);
    })
    .reduce((a, b) => [...a, ...b], []);
  console.log({ fieldsToProject });

  const { data, status, error } = useGetDataQuery({
    contentTypes: tplOptions.current.map((d) => d.kp_content_type),
    findQuery: { kp_published_status: "published" },
    projection: {
      ...fieldsToProject.reduce(
        (a, b) => ({ ...a, [`${b.valuePath}.annoData`]: 1 }),
        {}
      ),
      tags: 1,
      "meta.kp_content_type": 1,
    },
  });

  const separatedData =
    status === "success" &&
    separateEachAnnoIntoIndependentDoc({ data, selectedTpls });

  const filterRes = autoGenFilterConfigsFromTpl({
    selectedTpls,
    _ProfileTypes,
  });

  const screenWidth = useScreenWidth();
  const cols =
    screenWidth < getBreakPoint("xl")
      ? 1
      : screenWidth >= getBreakPoint("xl") && screenWidth < getBreakPoint("xxl")
      ? 2
      : 3;
  console.log({ separatedData, filterRes });

  const StyledCardShell = styled.div`
    border: 1px solid ${colors.greyColor5};
    border-radius: 0.5rem;
    cursor: pointer;
    background: ${colors.white};
    ${transition("box-shadow", "border-color")};
    &:hover {
      ${boxShadow1};
      border-color: ${colors.greyColor15};
    }
  `;

  const contentTypeSelectRef = useRef(null)

  return (
    <div>
      <SidebarLayoutV2
        gridHeightCSS="calc( 100vh - 60px )"
        lsbWidth={400}
        lsbComp={
          <>
          <div ref={contentTypeSelectRef}>
<div style={{padding: '1rem 2rem 0.5rem 2rem'}}><SANS_2>{'Selected Content Types:'}</SANS_2></div>
<div style={{padding: '0 2rem', paddingBottom: '1rem'}}>
          <DropdownMulti
            S
            placeholder={'Select Content Types'}
            // isTagsStyle
            options={tplOptions.current.map(d => ({display: d.general?.content?.title || d.kp_content_type, value: d.kp_content_type}))}
            value={selectedTpls.map(d => ({display: d.general?.content?.title || d.kp_content_type, value: d.kp_content_type}))}
            onChange={(k,v) => setSelectedTpls(v.map(d => tplOptions.current.find(dd => dd.kp_content_type === d.value)))}
            />
            </div>
            <Divider/>
          </div>
          
          <FilterModule
          expandedWidth={400}
          headerRef={contentTypeSelectRef}
            orientation="sidebar"
            filterConfig={autoGenFilterConfigsFromTpl({
              selectedTpls,
              _ProfileTypes,
            })}
            {...{ activeFilters, setActiveFilters }}
          />
          </>
        }
      >
        <Container style={{ background: colors.greyColor3 }}>
          <PaddingTopBottom20>
            {separatedData && (
              <div style={{ paddingBottom: "1rem" }}>
                <SANS_4>{`Total Count: ${
                  filterAsPerActiveFilters({ separatedData, activeFilters, selectedTpls })
                    ?.length
                }`}</SANS_4>
              </div>
            )}
            <div
              style={{
                gap: "2rem",
                display: "grid",
                gridTemplateColumns: makeArrayFromLength(cols)
                  .map((d) => "1fr")
                  .join(" "),
              }}
            >
              {status === "loading" ? (
                makeArrayFromLength(20).map((d, i) => (
                  <SkeletonLoader style={{ height: "300px" }} />
                ))
              ) : status === "error" ? (
                <ErrorStates errorResponseObject={error} />
              ) : (
                separatedData &&
                makeArrayFromLength(cols).map((col, colI) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    {filterAsPerActiveFilters({ separatedData, activeFilters, selectedTpls })
                      .filter((d, i) => i < cardsLimit && i % cols === colI)
                      .map((singleAnno, singleAnnoI, thisAry) => (
                        <a
                          target="_blank"
                          href={`${window.location.href}/published-page/${singleAnno.meta.kp_content_type}?id=${singleAnno._id}`}
                        >
                          <StyledCardShell
                            key={singleAnno._id + singleAnno.annoKey}
                            // className={singleAnnoI === thisAry.length - 1 && 'has ref'}
                            ref={
                              (singleAnnoI === thisAry.length - 1 &&
                              colI === cols - 1)
                                ? observerRef
                                : null
                            }
                          >
                            <div
                              style={{ padding: "2rem", paddingBottom: "0" }}
                            >
                              <div style={{ paddingBottom: "1rem" }}>
                                <MetaPrimary
                                  valuePath={"meta.contentTypeTitle"}
                                  content={singleAnno}
                                  align="left"
                                />
                              </div>
                              <LexicalTextEditor
                                value={singleAnno.fragment}
                                readOnly
                              />
                            </div>
                            <div
                              style={{
                                padding: "1rem",
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem",
                              }}
                            >
                              {genTagSectionOnCard({
                                tagsData: singleAnno.annoTags,
                                allTpls,
                                title: content.annoTagsTitle,
                              })}

                              <div>
                                {genTagSectionOnCard({
                                  tagsData: singleAnno.docTags,
                                  allTpls,
                                  title: content.docTagsTItle,
                                })}
                              </div>
                            </div>
                          </StyledCardShell>
                        </a>
                      ))}
                  </div>
                ))
              )}
            </div>
          </PaddingTopBottom20>
        </Container>
      </SidebarLayoutV2>
    </div>
  );
};
