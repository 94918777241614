import styled, { css } from "styled-components";
import { colors, transition } from "oolib";
const { greyColor5, greyColor15, white } = colors;

export const StyledAsideFilter = styled.div`
  ${({ top }) =>
    top !== undefined
      ? css`
          position: sticky;
          top: ${top}px;
        `
      : css`
          position: relative;
          height: 100%;
        `}
  background-color: ${white};
  z-index: 98;
`;

export const StyledAsideFilterContainer = styled.div`
  overflow: hidden;
  width: ${({ width }) => width + "px"};
  height: 100%;
  ${transition("width")};
`;

export const StyledAsideFilterContentsWrapper = styled.div`
  height: ${({ headerHeight }) =>
    headerHeight === 'auto' 
    ? 'auto'
    : headerHeight !== undefined ? `calc(100vh - ${headerHeight}px)` : "100%"};

  box-shadow: inset 2px 0px 10px ${greyColor15};

  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  width: ${({ width }) => width}px;
  transition: width 0.3s ease-out;
`;
export const SideFilterHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  background-color: ${greyColor5};
  align-items: center;
  /* height: fit-content; */
  height: 4rem;
  width: 100%;
`;

export const SideFilterListWrapper = styled.div`
  overflow-y: scroll;

  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    width: 0px; /* webkit */
  }
`;

export const SideFilterListStyled = styled.div`
  display: flex;
  /* gap: 0.5rem; */
  flex-direction: column;
`;

export const StyledFilterAccordionHeader = styled.div`
  display: flex;
  padding: 1rem 2rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const StyledOptionWrapper = styled.div`
  padding: 0.5rem 2rem;
  cursor: pointer;
  ${transition("background-color 0.3s")}

  @media (hover:hover) {
    &:hover {
      background-color: ${greyColor5};
    }
  }
`;

export const CheckBoxOptionStyled = styled.div`
  display: flex;
  gap: 1rem;
  cursor: pointer;
  padding: 0.5rem 2rem;
  transition: background-color 0.3s ease-out;
  border-radius: 5px;

  align-items: center;
  :hover {
    background-color: ${greyColor5};
  }
  button {
    flex-shrink: 0;
  }
  /* :first-child{
    margin-top: 1rem;
  } */
`;

export const StyledToggleButtonWrapper = styled.div`
  position: absolute;
  top: 30vh;
  right: 0;
  transform: translateX(100%);
`;
